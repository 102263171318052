import React from 'react';
import Page from 'components/pages/Page';
import routes from 'routes';
import styled from 'styled-components';
import { PageContent, OpacityLink } from 'components/ui';

const { writing } = routes;

const ArticleList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const WritingPage = () => {
  return (
    <Page title="Writing">
      <PageContent centered>
        <ArticleList>
          <li>
            <OpacityLink to={writing.demo.path}>Demo article</OpacityLink>
          </li>
          <li>
            <OpacityLink to={writing.howThisSiteWasMade.path}>
              How this site was made
            </OpacityLink>
          </li>
        </ArticleList>
      </PageContent>
    </Page>
  );
};

export default WritingPage;
